<template>
  <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="sign__content">
                    <form @submit.prevent="unsubscribe" class="sign__form" style="max-width: 600px;" method="POST">
                        <router-link to="/" class="sign__logo">
                            <img src="../../assets/Sudani-Logo.png" alt="" />
                        </router-link>
						<input type="hidden" name="serviceCode" v-model="serviceCode"/>
              			<input type="hidden" name="token" v-model="token" />
                        <p style="text-align: center;font-family: 'Cairo', sans-serif;font-size: 18px;">هل تريد إلغاء الإشتراك من منصة صحتك</p>
						<div class="sign__group" style="margin-bottom: 2px;">
                            <button class="sign__btn_unsub button">
                                <span v-if="loading" class="px-1 arabickufi">الغاء الاشتراك</span>  &nbsp;
                                <div v-if="loading" class="spinner-border spinner-border-sm"></div>
                                <span v-else class="arabickufi">الغاء الاشتراك</span>
                            </button>
                            <!-- <button class="sign__btn" style="margin: 30px 0 0 auto;" >الغاء الاشتراك</button> -->
						</div>
                        <div class="sign__group pointer" style="margin: 0px 0 0 auto;"></div>
                        <a class="sign__btn" style="margin: 30px 0 0 auto;" @click="goBack">رجـــــوع</a>
                    </form>
                    <!-- <div class="sign__group" style="margin-bottom: 2px;">
                        
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTPSPAY } from '@/Api/http-spay';
import { useToast } from "vue-toastification";
export default {
    data() {
        return {
            loading: false,
        };
    },
    methods: {
        goBack() {
            return this.$router.go(-1)
        },
      async unsubscribe () {
        const toast = useToast();
          let button = document.querySelector(".button");
          button.disabled = true;
        this.loading = true;
        await HTTPSPAY.post("SpayUnsubscribe.php?msisdn="+this.$cookie.getCookie("msisdn")+"&serviceId=2222&opratorID=2").then((response) => {
          if (response.data.status === 0) {
            toast.error("انت لست مشترك في منصة صحتك", { timeout: 2500 });
            this.$cookie.keys().forEach(cookie => this.$cookie.removeCookie(cookie))
            this.$router.push('/')
          } else {
            toast.success("تم الغاء اشتراكك من منصة صحتك ", { timeout: 2500 });
            this.$cookie.keys().forEach(cookie => this.$cookie.removeCookie(cookie))
            this.$router.push('/')
          }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            this.loading = false;
        });
      },
   }
}
</script>

<style>
.pointer {
  cursor: pointer;
}

.sign__btn_unsub {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 15px auto;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  font-size: 15px;
  color: #fffefe;
  text-transform: uppercase;
  font-weight: 600;
  font-family: 'Noto Kufi Arabic', sans-serif !important;
  background-color: red;
}
</style>